import React from "react";
import { graphql } from "gatsby";
import Layout from "@common/Layout/Black-White";
import { ArticlePage } from "@components/BW-Blog/components";
import SEO from "../components/Common/SEO/SEO";
import { GLOBAL_APPLICATION_EXTERNAL_LINKS } from "@links"

const ArticleTemplate = ({ data }) => {
    const {
        strapiArticle: post,
        allStrapiArticle: { edges: postsList },
    } = data;

    const postSchema = {
        "@context": "https://schema.org",
        "@type": "Article",
        headline: post.title,
        image: post.cover.formats.thumbnail.url,
        author: {
            "@type": "Person",
            name: post.author.name,
        },
        publisher: {
            "@type": "Organization",
            name: "Impressit",
        },
        datePublished: post.date,
    };

    return (
        <>
            <SEO
                title={post.meta_title}
                titleTemplate="Impressit"
                description={post.meta_description}
                keywords={post.meta_keywords}
                image={post.cover.formats.thumbnail.url}
                url={`${GLOBAL_APPLICATION_EXTERNAL_LINKS.IMPRESSIT_BLOG_ARTICLE}${post.meta_url}`}
                schema={postSchema}
            />
            <Layout hasCatchupSection>
                <ArticlePage post={post} postsList={postsList} />
            </Layout>
        </>
    );
};

export default ArticleTemplate;

export const query = graphql`
    query ArticleTemplate($id: String) {
        strapiArticle(id: { eq: $id }) {
            id
            title
            content
            exctract_content
            date
            read_time
            meta_url
            meta_title
            meta_keywords
            meta_description
            author {
                name
                avatar {
                    alternativeText
                    formats {
                        thumbnail {
                            url
                        }
                    }
                    name
                }
                role
                about
            }
            cover {
                alternativeText
                formats {
                    large {
                        url
                    }
                    medium {
                        url
                    }
                    small {
                        url
                    }
                    thumbnail {
                        url
                    }
                }
            }
            categories {
                name
            }
        }
        allStrapiArticle {
            edges {
                node {
                    id
                    title
                    content
                    exctract_content
                    date
                    read_time
                    meta_url
                    meta_title
                    meta_keywords
                    meta_description
                    author {
                        name
                        avatar {
                            formats {
                                thumbnail {
                                    url
                                }
                            }
                            name
                        }
                        role
                    }
                    cover {
                        alternativeText
                        formats {
                            large {
                                url
                            }
                            medium {
                                url
                            }
                            small {
                                url
                            }
                            thumbnail {
                                url
                            }
                        }
                    }
                    categories {
                        name
                    }
                }
            }
        }
    }
`;
